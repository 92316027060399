<template>
  <a-input ref="el" size="large" type="email" v-bind="$attrs" />
</template>

<script>
  export default {
    name: 'UiEmailInput',
    inheritAttrs: false,
  }
</script>

<style scoped>
</style>
