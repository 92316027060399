<route>
  {
  "name": "reset-password-step1",
  "meta": {
  "auth": false
  }
  }
</route>

<template>
  <div v-if="!isLinkSended" class="flex-column flex-grow">
    <div class="wrapper">
      <a-form
        ref="formRef"
        class="form"
        :model="values"
        :rules="rules"
        :hideRequiredMark="true"
        @submit.prevent="handleSubmit"
      >
        <ui-link to="/login">
          <img
            src="@/assets/images/BackArrow.svg"
            alt="back-arrow"
            class="mt-8"
          /> </ui-link
        ><br /><br />
        <div class="title1 mb-8">
          {{ $t('reset-password-step1.reset_password') }}
        </div>
        <div class="text1 mb-32">
          {{ $t('reset-password-step1.don_t_worry_happens_to_the') }}
        </div>
        <ui-form-item label="Your E-mail" name="email">
          <ui-email-input
            placeholder="ex. example@gmail.com"
            v-model:value="values.email"
            style="width: 257px;"
          />
        </ui-form-item>
        <br />
        <div class="center">
          <ui-button
            style="width: 100%"
            @click="handleSubmit"
            >{{ $t('reset-password-step1.email_me_a_recover_link') }}</ui-button
          >
        </div>
      </a-form>
    </div>
  </div>
  <div v-else class="flex-column flex-grow">
    <div class="wrapper">
      <ui-link to="/login">
        <img
          src="@/assets/images/BackArrow.svg"
          alt="back-arrow"
          class="mt-8"
        /> </ui-link
      ><br /><br />
      <div class="title1 mb-8">
        {{ $t('reset-password-step1.reset_password') }}
      </div>
      <div class="text1">
        An email has been sent to {{ values.email }}. Please, click on the link
        to reset your password
      </div>
    </div>
  </div>
</template>

<script>
import { authManagementClient } from '@/plugins/feathersClient'
import UiFormItem from '../components/ui/UiFormItem'
import UiEmailInput from '../components/ui/UiEmailInput'
import UiButton from '../components/ui/UiButton'
import UiLink from '../components/ui/UiLink'

export default {
  layout: 'auth',
  name: 'reset-password-step1',
  components: {
    UiLink,
    UiFormItem,
    UiEmailInput,
    UiButton,
  },
  data() {
    return {
      form: {},
      values: {
        email: '',
      },
      rules: {
        email: [
          {
            // eslint-disable-next-line no-useless-escape
            pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            whitespace: true,
            message: 'Please, enter a valid email ',
            trigger: 'blur',
          },
        ],
      },
      isLinkSended: false,
      hadValidationError: false,
    }
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault()

      try {
        await this.$refs.formRef.validate()
      } catch (error) {
        console.log('error', error)
        return
      }

      try {
        await authManagementClient.sendResetPwd({
          email: this.values.email,
        })
        this.isLinkSended = true
      } catch (err) {
        console.log('error', err)
        this.$notify.parseFeathersErrors(err)
      }
    },

    resetForm() {
      this.form.resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 45px 70px;
  align-items: center;
}
.form {
  width: 100%;
  min-height: 280px;
}
.footer {
  padding: 32px 0 16px 0;
}
.center {
  text-align: center;
}
</style>
